import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const YearWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.textColors.tertiary};
  height: 3em;
  width: 100%;
  margin-left: 0.5em;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: auto;
  }
`;
const YearLabel = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.3em;
  color: #ffffff;
`;

const YearTile = ({ year }) => (
  <YearWrapper>
    <YearLabel>{year}</YearLabel>
  </YearWrapper>
);

YearTile.propTypes = {
  year: PropTypes.string.isRequired
};

export default YearTile;
