import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../theme/components';

const TextBox = styled.div`
  width: 100%;
  height: 80px;
  z-index: 2;
`;
const StyledText = styled(Text)`
  width: 100%;
  text-align: center;
  line-height: 1.4;
`;

const TutorialHeader = ({ children }) => {
  return (
    <TextBox>
      <StyledText>{children}</StyledText>
    </TextBox>
  );
};

export default TutorialHeader;
