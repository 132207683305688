import React, { useReducer, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import DropdownComponent from './DropdownComponent';
import { MenuStateContext } from './../../layout/SideBarContainer';

import { toggler } from './../../data/hooksReducers';

const DropdownContainer = ({ children, dropHeader }) => {
  const [state, dispatch] = useReducer(toggler, { show: false });
  const [header, setHeader] = useState('');
  const hideDropdown = useContext(MenuStateContext);
  const toggleDropdown = () => dispatch({ type: 'toggleState' });

  useEffect(() => setHeader(dropHeader), [dropHeader]);
  useEffect(
    () => {
      if (!hideDropdown && state.show) return toggleDropdown;
    },
    [hideDropdown]
  );

  return (
    <DropdownComponent
      showDropdown={state.show}
      header={header}
      toggleDropdown={toggleDropdown}
    >
      {children}
    </DropdownComponent>
  );
};

DropdownContainer.propTypes = {
  dropHeader: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideDropdown: PropTypes.bool
};
DropdownContainer.defaultProps = {
  hideDropdown: false
};

export default DropdownContainer;
