import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const EditorBox = styled.div`
  width: 100%;
  height: 35vh;
  margin: 5% 0;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    height: 100%;
  }
`;

const ResolutionEditor = ({ textEditor, handleChange }) => {
  const editorElement = useRef(null);
  useEffect(() => initEditor(), []);

  const initEditor = () => {
    const options = {
      theme: 'snow',
      placeholder: 'Tekst uchwały',
      modules: {
        toolbar: [
          [{ font: [] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ align: [] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }]
        ],
        table: true
      }
    };

    const editor = new Quill(editorElement.current, options);
    editor.root.innerHTML = textEditor;
    editor.on('text-change', () => {
      handleChange(editor.root.innerHTML);
    });
  };

  return (
    <EditorBox>
      <div ref={editorElement} />
    </EditorBox>
  );
};

ResolutionEditor.propTypes = {
  textEditor: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default ResolutionEditor;
