import React, { memo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const FrequencyBarBox = styled.div`
  width: 100%;
  max-width: 600px;
  text-align: left;
  word-break: break-word;
`;
const FrequencyChart = styled.div`
  position: relative;
  height: 1.6em;
  background: ${({ theme }) => theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.borderColor};
  width: 100%;
`;
const FrequencyHalfPoint = styled.div`
  position: absolute;
  top: 0;
  left: 49%;
  height: 100%;
  border-right: 2px solid ${({ theme }) => theme.backgroundColors.primary};
`;
const FrequencyBar = styled.div`
  width: ${({ percent }) => `${percent}%`};
  max-width: 100%;
  height: 100%;
  background: ${({ percent, theme }) =>
    percent > 50
      ? theme.backgroundColors.primary
      : theme.backgroundColors.quaternary};
`;

const ProgressBar = memo(({ percent }) => {
  return (
    <FrequencyBarBox>
      <FrequencyChart>
        <FrequencyHalfPoint />
        <FrequencyBar percent={percent} />
      </FrequencyChart>
    </FrequencyBarBox>
  );
});

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired
};

export default ProgressBar;
