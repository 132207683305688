import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import Filter from '../case/components/Filter';
import SearchInput from '../case/components/SearchInput';
import { setActiveFilter } from '../data/case/actions';
import { fetchUser } from '../data/user/actions';
import { fetchCompleteTutorial } from '../helpers/api/user';
import { userRoles } from '../helpers/constants';
import { isMessagingSupported } from '../helpers/firebase';
import AddCase from '../shared/components/buttons/AddCase';
import Loader from '../shared/components/Loader';
import Modal from '../shared/components/Modal';
import Error from '../shared/Error';
import UnvotedResolutions from '../shared/popups/UnvotedResolutions';
import WelcomePageContainer from '../shared/popups/WelcomePageContainer';
import CurrentResource from './../case/components/CurrentResource';
import {
  getUnvotedDisplayed,
  saveLastVisit,
  setUnvotedDisplayed
} from './../helpers/localStorage';
import NotificationBanner from './components/NotificationBanner';
import NotificationsButton from './components/NotificationsButton';
import SideBar from './SideBarContainer';
import TutorialContainer from './tutorial/TutorialContainer';

const AppWrapper = styled.main`
  text-align: center;
  padding-bottom: ${({ paddingBottom }) => paddingBottom && '4em'};
`;
const TopBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5em;
  padding: 0 2em;
  margin-top: 1em;
  background: ${({ theme }) => theme.backgroundColors.primary};
  z-index: 2;
`;
const StyledAddCase = styled(AddCase)`
  position: fixed;
  right: 3em;
  bottom: 6em;
  z-index: 3;
`;

class Layout extends Component {
  state = {
    showTutorial: false,
    isWelcomePageOpen: false,
    generatedPass: true,
    openUnvotedPopup: false,
    isLoading: false,
    error: null
  };

  componentWillMount() {
    this.props.history.replace({
      state: {}
    });
  }

  componentDidMount() {
    const { fetchUser } = this.props;
    fetchUser();
    saveLastVisit(new Date());
    this.setState({ generatedPass: true });
    this.manageNotofications();
  }

  manageNotofications = () => {
    const { tutorialCompleted, isRegularUser } = this.props;

    isRegularUser && this.toggleWelcomePage(!tutorialCompleted);
    const displayUnvoted = getUnvotedDisplayed();
    if (displayUnvoted === 'false' && tutorialCompleted) {
      this.toggleUnvotedResolutions(true);
      setUnvotedDisplayed(true);
    }
  };

  componentDidUpdate(prevProps) {
    const {
      tutorialCompleted,
      defaultPassword,
      history,
      fetchUser
    } = this.props;

    if (history.location.state && history.location.state.updateUser) {
      history.replace({
        state: {}
      });
      fetchUser();
    }

    if (prevProps.tutorialCompleted !== tutorialCompleted)
      this.toggleWelcomePage(!tutorialCompleted);
    if (prevProps.defaultPassword !== defaultPassword) {
      this.setState({ generatedPass: false });
    }
  }

  toggleTutorial(toggle, event, callback) {
    event && event.stopPropagation();
    this.setState({ showTutorial: toggle }, () => {
      callback && callback();
    });
  }

  toggleWelcomePage = (isWelcomePageOpen = true) => {
    this.setState({ isWelcomePageOpen });
  };

  toggleUnvotedResolutions = (openUnvotedPopup = true) => {
    if (openUnvotedPopup === false) {
      this.setState({ openUnvotedPopup }, () =>
        this.props.setActiveFilter('resolutions')
      );
    } else this.setState({ openUnvotedPopup });
  };

  closeWelcomePage = () => {
    const { fetchUser, tutorialCompleted } = this.props;
    this.toggleWelcomePage(false);
    if (!tutorialCompleted) fetchCompleteTutorial().then(fetchUser);
  };

  renderTutorialModal(showTutorial) {
    return (
      showTutorial && (
        <Modal closeModal={e => this.toggleTutorial(false, e)} isWide>
          <TutorialContainer
            closeTutorial={e => this.toggleTutorial(false, e)}
          />
        </Modal>
      )
    );
  }

  handleFilterChange = filter => {
    const { resolutionAccess, setActiveFilter, history } = this.props;
    if (filter === 'resolutions' && !resolutionAccess) {
      history.push('/funkcje/voteResolution');
    } else {
      setActiveFilter(filter);
    }
  };

  render() {
    const {
      children,
      menu,
      addCaseButton,
      apartmentLabel,
      search,
      history,
      defaultPassword,
      activeFilter,
      query,
      showCommunityContacts,
      showCommunityVotePower,
      communityStatus,
      isRegularUser,
      isLoading,
      isKeeper,
      showUserVotePower,
      unvotedResolutionsNumber,
      showResolutionFilter
    } = this.props;
    const {
      showTutorial,
      isWelcomePageOpen,
      generatedPass,
      openUnvotedPopup
    } = this.state;
    const hideFilter = query.length >= 1;
    const premiumCommunity = communityStatus === 'premium';
    const showUnvotedResolutions =
      openUnvotedPopup && unvotedResolutionsNumber > 0;

    if (defaultPassword && !generatedPass) return <Redirect to="/nowehaslo" />;

    if (history.location.state && history.location.state.error)
      return <Error />;
    if (isLoading) return <Loader />;
    return (
      <>
        {isWelcomePageOpen &&
          isKeeper && (
            <WelcomePageContainer closeWelcomePage={this.closeWelcomePage} />
          )}
        {showUnvotedResolutions && (
          <UnvotedResolutions
            unvotedResolutionsNumber={unvotedResolutionsNumber}
            closeUnvoted={() => this.toggleUnvotedResolutions(false)}
          />
        )}
        <AppWrapper paddingBottom={addCaseButton}>
          {menu && (
            <SideBar
              toggleTutorial={e => this.toggleTutorial(true, e)}
              showCommunityContacts={showCommunityContacts}
              showCommunityVotePower={showCommunityVotePower}
              showUserVotePower={showUserVotePower}
              premiumCommunity={premiumCommunity}
              isRegularUser={isRegularUser}
            />
          )}
          {addCaseButton && <StyledAddCase to="/kategorie" />}
          {apartmentLabel &&
            !showTutorial && <CurrentResource isRegularUser={isRegularUser} />}
          {search && (
            <>
              <TopBar>
                <SearchInput />
                <NotificationsButton />
              </TopBar>
              <Filter
                activeFilter={activeFilter}
                clickHandler={this.handleFilterChange}
                hideFilter={hideFilter}
                unvotedResolutionsNumber={unvotedResolutionsNumber}
                showResolutionFilter={showResolutionFilter}
              />
              {isMessagingSupported() && <NotificationBanner />}
            </>
          )}
          {children}
          {this.renderTutorialModal(showTutorial)}
        </AppWrapper>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  menu: PropTypes.bool,
  tutorialCompleted: PropTypes.bool,
  fetchUser: PropTypes.func.isRequired,
  addCaseButton: PropTypes.bool,
  search: PropTypes.bool,
  activeFilter: PropTypes.string.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  showCommunityContacts: PropTypes.bool,
  showCommunityVotePower: PropTypes.bool,
  showUserVotePower: PropTypes.bool,
  query: PropTypes.string,
  isKeeper: PropTypes.bool,
  isLoading: PropTypes.bool,
  unvotedResolutionsNumber: PropTypes.number
};

Layout.defaultProps = {
  menu: false,
  addCaseButton: false,
  tutorialCompleted: true,
  defaultPassword: false,
  search: false,
  showCommunityContacts: false,
  showCommunityVotePower: false,
  showUserVotePower: false,
  query: '',
  isKeeper: false,
  isLoading: false,
  unvotedResolutionsNumber: 0
};

const mapStateToProps = state => ({
  tutorialCompleted: state.user.profile.data
    ? state.user.profile.data.tutorial_completed
    : true,
  defaultPassword:
    state.user.profile.data && state.user.profile.data.default_password,
  communityStatus:
    state.user.currentDataResource.data &&
    state.user.currentDataResource.data.status,
  activeFilter: state.case.activeFilter,
  query: state.case.query.query,
  showCommunityContacts: state.user.accessControlList.data
    ? state.user.accessControlList.data.community.contact_number
    : false,
  showCommunityVotePower: state.user.accessControlList.data
    ? state.user.accessControlList.data.community.vote_strength_calculator
    : false,
  isRegularUser:
    state.user.profile.data &&
    state.user.profile.data.role === userRoles.ROLE_USER,
  showUserVotePower: state.user.accessControlList.data
    ? state.user.accessControlList.data.user.strength_calculator_view
    : false,
  isKeeper:
    state.user.profile.data &&
    (state.user.profile.data.role === userRoles.ROLE_WARDEN ||
      state.user.profile.data.role === userRoles.ROLE_KEEPER),
  isCompany:
    state.user.profile.data &&
    state.user.profile.data.role === userRoles.ROLE_COMPANY,
  isLoading: state.user.currentDataResource
    ? state.user.currentDataResource.isLoading
    : '',
  unvotedResolutionsNumber:
    state.case && state.case.unvotedResolutionsNumber.data,
  resolutionAccess: state.user.accessControlList.data
    ? state.user.accessControlList.data.community.resolution
    : false,
  showResolutionFilter: state.user.accessControlList.data
    ? state.user.accessControlList.data.user.resolution_access
    : false
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser()),
  setActiveFilter: filter => dispatch(setActiveFilter(filter))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
