import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import ExitIcon from './buttons/ExitIcon';

const ModalBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  cursor: default;
`;
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: ${({ isWide }) => !isWide && '80vh'};
  height: ${({ isWide }) => (isWide ? '100vh' : '100%')};
  max-width: ${({ isWide }) => !isWide && '450px'};
  width: ${({ isWide }) => (isWide ? '100%' : '95%')};
  background: ${({ theme }) => theme.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  border-radius: 5px;
  z-index: 100;
`;
const ModalContent = styled.div`
  max-width: ${({ isWide }) => (isWide ? '536px' : '100%')};
  width: 100%;
  height: 100%;
  padding: 1.5em;
  word-break: break-word;
  text-align: center;
`;

const Modal = ({ closeModal, children, isWide, noBackgroundClosing }) => {
  let modalRef = React.createRef();
  const backGroundClickHandler = event =>
    !modalRef.current.contains(event.target) && closeModal(event);
  const isBackgroundClosing = noBackgroundClosing
    ? null
    : backGroundClickHandler;

  return (
    <ModalBackground onClick={isWide ? null : isBackgroundClosing}>
      <ModalWrapper isWide={isWide}>
        <ExitIcon onClick={closeModal} />
        <ModalContent ref={modalRef} isWide={isWide}>
          {children}
        </ModalContent>
      </ModalWrapper>
    </ModalBackground>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isWide: PropTypes.bool,
  noBackgroundClosing: PropTypes.bool
};

Modal.defaultProps = {
  isWide: false,
  noBackgroundClosing: false
};

export default Modal;
