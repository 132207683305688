import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LabelColored } from '../../layout/theme/components';
import TooltipWrapper from './TooltipWrapper';
import AvatarIcon from './avatar/AvatarIcon';
import AvatarDetailsModal from './avatar/AvatarDetailsModal';

import { userLabels, userRoles } from '../../helpers/constants';
import { fetchUserDetails } from '../../helpers/api/user';

const UserWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  word-break: break-word;
  color: ${({ userRole, theme }) => {
    switch (userRole) {
      case userRoles.ROLE_WARDEN: {
        return theme.userColors.warden;
      }
      case userRoles.ROLE_KEEPER: {
        return theme.userColors.keeper;
      }
      case userRoles.ROLE_COMPANY: {
        return theme.userColors.company;
      }
      default: {
        return theme.textColors.secondary;
      }
    }
  }};
`;
const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.5em;
`;
const UserNameText = styled(LabelColored)`
  font-family: 'Montserrat', sans-serif;
  color: inherit;
`;

class UserLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showUserDetails: false,
      cursorOnAvatarDetailsModal: false,
      top: true,
      details: []
    };
  }

  wrapperFunction(isOpen, event) {
    const { showUserDetails, hasPermission, pageYLimit } = this.props;
    const { cursorOnAvatarDetailsModal } = this.state;
    if (!showUserDetails || !hasPermission || cursorOnAvatarDetailsModal) {
      return false;
    }
    return this.setState({ showModal: isOpen, top: event.pageY > pageYLimit });
  }

  toogleAvatarDetailsStatus(isOpen) {
    return this.setState({ cursorOnAvatarDetailsModal: isOpen });
  }

  componentDidMount() {
    const { userId, hasPermission } = this.props;
    if (userId === null || !hasPermission) {
      return false;
    }

    fetchUserDetails(userId).then(({ data }) =>
      this.setState({ details: data })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { userId, hasPermission, userRole } = this.props;
    const { cursorOnAvatarDetailsModal } = this.state;
    if (
      userId !== null &&
      hasPermission &&
      hasPermission !== prevProps.hasPermission &&
      userRole !== userRoles.ROLE_ADMIN
    ) {
      fetchUserDetails(userId).then(({ data }) =>
        this.setState({ details: data })
      );
    }
    if (
      cursorOnAvatarDetailsModal !== prevState.cursorOnAvatarDetailsModal &&
      cursorOnAvatarDetailsModal
    ) {
      this.setState({ showModal: true });
    }
    if (
      cursorOnAvatarDetailsModal !== prevState.cursorOnAvatarDetailsModal &&
      !cursorOnAvatarDetailsModal
    ) {
      this.setState({ showModal: false });
    }
  }

  shouldShowTooltip() {
    const { hasPermission, showTooltip, showUserDetails } = this.props;
    const { details } = this.state;
    return (
      showTooltip &&
      (!showUserDetails || !hasPermission || details.length === 0)
    );
  }

  shouldShowModal() {
    const { showModal, details } = this.state;
    const { hasPermission, showUserDetails, userRole } = this.props;
    if (
      userRole === userRoles.ROLE_ADMIN ||
      userRole === userRoles.ROLE_ANONYMOUS ||
      details.length === 0
    ) {
      return false;
    }
    return hasPermission && showModal && showUserDetails;
  }

  render() {
    const { top, details } = this.state;
    const { avatarPath, userRole, userName, userId } = this.props;

    return (
      <TooltipWrapper
        tooltip={this.shouldShowTooltip() && userLabels[userRole]}
      >
        {this.shouldShowModal() && (
          <AvatarDetailsModal
            isAnonymous={userId === null}
            details={details}
            top={top}
            closeModal={() => this.toogleAvatarDetailsStatus(false)}
            onMouseEnter={() => this.toogleAvatarDetailsStatus(true)}
            onMouseLeave={() => this.toogleAvatarDetailsStatus(false)}
          />
        )}
        <UserWrapper
          userRole={userRole}
          onClick={e => this.wrapperFunction(true, e)}
          onMouseEnter={e => {
            e.persist();
            setTimeout(() => this.wrapperFunction(true, e), 500);
          }}
          onMouseLeave={e => {
            e.persist();
            setTimeout(() => this.wrapperFunction(false, e), 500);
          }}
        >
          <AvatarIcon
            userRole={userRole}
            path={avatarPath}
            top={top}
            closeModal={this.closeModal}
            hideBorder={userRole === userRoles.ROLE_USER}
          />
          <UserNameWrapper>
            <UserNameText>{userName}</UserNameText>
          </UserNameWrapper>
        </UserWrapper>
      </TooltipWrapper>
    );
  }
}

UserLabel.propTypes = {
  userName: PropTypes.string,
  userId: PropTypes.number,
  userRole: PropTypes.string,
  avatarPath: PropTypes.string,
  menu: PropTypes.bool,
  pageYLimit: PropTypes.number,
  showTooltip: PropTypes.bool,
  showUserDetails: PropTypes.bool
};

UserLabel.defaultProps = {
  userName: null,
  userId: null,
  userRole: null,
  avatarPath: '',
  menu: false,
  pageYLimit: 350,
  showTooltip: false,
  showUserDetails: false
};

const mapStateToProps = state => ({
  hasPermission: state.user.accessControlList.data
    ? state.user.accessControlList.data.user.user_details
    : null,
  role: state.user.profile.data ? state.user.profile.data.role : false
});

export default connect(
  mapStateToProps,
  null
)(UserLabel);
