import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components/macro';

import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import TextButton from '../../../shared/components/buttons/TextButton';

import AttachmentIcon from '../../../../assets/attachment';
import CommentIcon from '../../../../assets/comment';
import DraftIcon from '../../../../assets/draftIcon';
import { RANGE_ICONS } from '../../../helpers/constants';
import { formatDateTime } from '../../../helpers/date';
import { Text } from '../../../layout/theme/components';

const ListItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  max-width: 900px;
  min-height: 100px;
  margin: 0 1em 1em;
  padding: 1.5em;
  border: 1px solid ${({ highlighted, theme }) => (highlighted ? theme.buttonColors.primary : theme.borderColor)};
  border-radius: 10px;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 0 auto 1em;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  width: 57px;
  height: 57px;
  margin: 0.5em;
  background: ${({ color }) => color};
  opacity: 0.7;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
const CaseIcon = styled.img`
  width: 70%;
  height: 70%;
`;
const CaseDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  padding-left: 2em;
  word-break: break-word;
`;
const TopicText = styled(Text)`
  margin: 0.5em 0 1em;
  flex: 1;
  text-align: left;
`;
const SideData = styled.div`
  color: #8c8c8c;
  font-size: 1.2em;
  display: flex;
  line-height: 160%;
`;
const RangeIcon = styled.span`
  width: 20px;
`;
const CaseActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
const Counter = styled.span`
  display: flex;
  justify-self: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  font-size: 1.2em;
  margin: 0 0.5em;
  height: 2em;
  svg {
    height: 1em;
    margin-left: 0.3em;

    &:last-of-type {
      margin-left: 0.5em;
    }
  }
  + button {
    display: flex;
    align-self: flex-end;
  }
`;
const YourVote = styled.span`
  font-weight: 100;
  margin-right: 5px;
`;
const Dot = styled.div`
  position: absolute;
  left: 1em;
  top: 1em;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${({ theme }) => theme.buttonColors.primary};
`;
const DraftInfo = styled.div`
  width: 100%;
  text-align: right;
  font-size: 1.2em;
  color: ${({ theme }) => theme.labels.negative};
`;
const DeleteDraft = styled(TextButton)`
  width: auto;
  height: 15px;
  padding: 0;
  margin: 0;
  font-size: 0.9em;
`;

const CaseItem = ({
  topic,
  hasAttachment,
  commentsCount,
  newCase,
  newActivity,
  iconPath,
  categoryColor,
  openSounding,
  soundingExist,
  userVote,
  userVoteLabel,
  clickHandler,
  isDraft,
  status,
  rangeSlug,
  toggleModal,
  createdAt,
}) => {
  return (
    <ListItem highlighted={newCase} onClick={clickHandler}>
      {newActivity && <Dot />}
      <IconWrapper color={categoryColor}>
        {isDraft ? <CaseIcon as={DraftIcon} /> : <CaseIcon src={iconPath} />}
      </IconWrapper>
      <CaseDetails>
        <TopicText isDraft={isDraft}>{topic}</TopicText>
        {isDraft ? (
          <>
            <DeleteDraft small onClick={toggleModal}>
              Usuń
            </DeleteDraft>
            <DraftInfo>Wersja robocza</DraftInfo>
          </>
        ) : (
          <>
            <SideData>
              {createdAt && formatDateTime(createdAt)}
              <RangeIcon>{RANGE_ICONS[rangeSlug]}</RangeIcon>
            </SideData>
            <CaseActions>
              {
                <Counter>
                  {commentsCount}
                  {<CommentIcon />}
                  {hasAttachment && <AttachmentIcon />}
                </Counter>
              }
              {soundingExist && (
                <SecondaryButton disabled={userVote !== null || status === 'closed'} onClick={openSounding}>
                  {userVote !== null ? (
                    <>
                      <YourVote>twój głos: </YourVote> {userVoteLabel}
                    </>
                  ) : (
                    'sondaż'
                  )}
                </SecondaryButton>
              )}
            </CaseActions>
          </>
        )}
      </CaseDetails>
    </ListItem>
  );
};

const Case = memo(CaseItem, (prevProps, nextProps) => {
  return (
    !(prevProps.openSounding === nextProps.openSounding) &&
    !(prevProps.clickHandler === nextProps.clickHandler) &&
    !(prevProps.toggleModal === nextProps.toggleModal)
  );
});

CaseItem.propTypes = {
  topic: PropTypes.string,
  hasAttachment: PropTypes.bool.isRequired,
  commentsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  iconPath: PropTypes.string.isRequired,
  categoryColor: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  openSounding: PropTypes.func.isRequired,
  soundingExist: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  userVote: PropTypes.number,
  userVoteLabel: PropTypes.string,
  newCase: PropTypes.bool,
  newActivity: PropTypes.bool,
  isDraft: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  rangeSlug: PropTypes.string,
  createdAt: PropTypes.string,
};

CaseItem.defaultProps = {
  topic: '',
  newCase: false,
  newActivity: false,
  userVote: null,
  userVoteLabel: '',
  soundingExist: false,
  rangeSlug: null,
  createdAt: null,
};

export default Case;
