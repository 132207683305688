import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UserProfileComponent from './components/UserProfileComponent';

import { fetchUser } from '../../data/user/actions';
import Loader from '../../shared/components/Loader';
import Error from '../../shared/Error';
import {
  areNotificationsActive,
  getFirebaseToken,
  getDeviceName,
  isMessagingSupported
} from '../../helpers/firebase';
import {
  addFirebaseDevice,
  removeFirebaseDevice
} from '../../helpers/api/firebase';

const UserProfileContainer = props => {
  const device = getDeviceName();
  const { profile, isLoading, error, fetchUser } = props;
  const [notifications, setNotifications] = useState(false);
  const [notifiactionsError, setNotifiactionsError] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(
    () => {
      if (profile) {
        const { push_devices } = profile;
        setNotifications(areNotificationsActive(push_devices));
      }
    },
    [profile]
  );

  const toggleNotifications = () => {
    if (notifications) {
      disableNotifications();
    } else {
      enableNotifications();
    }
  };

  const enableNotifications = () => {
    getFirebaseToken()
      .then(currentToken => {
        if (currentToken) {
          addFirebaseDevice(currentToken, device);
          setNotifications(true);
          setNotifiactionsError(null);
        }
      })
      .catch(err =>
        setNotifiactionsError(
          'Wystąpił bład przy włączaniu notyfikacji. Upewnij się, ze zezwoliłeś na powiadomienia z naszej strony.'
        )
      );
  };

  const disableNotifications = () => {
    removeFirebaseDevice(device)
      .then(() => {
        setNotifications(false);
      })
      .catch(err =>
        setNotifiactionsError(
          'Wystapił błąd przy wyłączaniu notyfikacji. Spróbuj ponownie za chwilę.'
        )
      );
  };

  if (isLoading) return <Loader />;
  if (error) return <Error />;

  const { userLabel, email, phone, avatar } = profile;
  return (
    <UserProfileComponent
      name={userLabel}
      phone={phone}
      email={email}
      avatarPath={avatar ? avatar.path : ''}
      notificationsSupported={isMessagingSupported()}
      notificationsActive={notifications}
      toggleNotifications={toggleNotifications}
      notifiactionsError={notifiactionsError}
    />
  );
};

UserProfileContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  profile: PropTypes.object,
  fetchUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.user.profile.data,
  isLoading: state.user.profile.isLoading,
  error: state.user.profile.error
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUser())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);
