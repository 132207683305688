import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import HamburgerIcon from './components/HamburgerIcon';
import SideBar from './SideBarComponent';

import { toggler } from './../data/hooksReducers';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
`;

export const MenuStateContext = React.createContext(false);

const SideBarWrapper = ({
  showCommunityContacts,
  showCommunityVotePower,
  showUserVotePower,
  premiumCommunity,
  toggleTutorial,
  isRegularUser
}) => {
  const [state, dispatch] = useReducer(toggler, { show: false });
  const toggleMenu = () => dispatch({ type: 'toggleState' });
  const [openHelp, toggleHelp] = useState(false);
  const helpToggle = () => toggleHelp(!openHelp);

  useEffect(
    () => {
      if (!state.show && openHelp) return helpToggle;
    },
    [state.show]
  );

  return (
    <MenuStateContext.Provider value={state.show}>
      <HamburgerIcon onClick={toggleMenu} active={state.show} />
      <SideBar
        showMenu={state.show}
        toggleTutorial={() => toggleTutorial()}
        showCommunityContacts={showCommunityContacts}
        showCommunityVotePower={showCommunityVotePower}
        showUserVotePower={showUserVotePower}
        premiumCommunity={premiumCommunity}
        helpToggle={helpToggle}
        openHelp={openHelp}
        isRegularUser={isRegularUser}
      />
      {state.show && <Backdrop onClick={toggleMenu} />}
    </MenuStateContext.Provider>
  );
};

SideBarWrapper.propTypes = {
  showCommunityContacts: PropTypes.bool.isRequired,
  showCommunityVotePower: PropTypes.bool.isRequired,
  showUserVotePower: PropTypes.bool.isRequired,
  toggleTutorial: PropTypes.func.isRequired,
  premiumCommunity: PropTypes.bool,
  isRegularUser: PropTypes.bool
};

SideBarWrapper.defaultProps = {
  premiumCommunity: false,
  isRegularUser: false
};

export default SideBarWrapper;
