import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Container from './../../../shared/components/Container';
import AvatarBox from './AvatarBox';
import ArrowIcon from './../../../shared/components/ArrowIcon';
import { H2, Text } from './../../../layout/theme/components';
import DataError from './DataError';
import DeleteAccount from './DeleteAccount';
import NotificationSection from './NotificationSection';

const TextBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 2em 0;
`;
const DataWrapper = styled.div`
  width: 100%;
  flex: 1;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const sectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;
const TitleBox = styled.div`
  ${sectionStyle};
  width: 20%;
`;
const DataBox = styled.div`
  ${sectionStyle};
  width: 80%;
`;
const StyledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.secondary};
`;
const StyledLabelText = styled(Text)`
  color: ${({ theme }) => theme.textColors.tertiary};
`;
const Box = styled.div`
  height: 3.5em;
  display: flex;
  align-items: flex-end;
  justify-content: start;
`;

const UserProfileComponent = ({
  name,
  phone,
  email,
  avatarPath,
  notificationsActive,
  toggleNotifications,
  notifiactionsError,
  notificationsSupported
}) => (
  <Container>
    <Link to="/">
      <ArrowIcon />
    </Link>
    <H2>Profil użytkownika</H2>
    <AvatarBox avatarPath={avatarPath} />
    <Text bold>{name}</Text>
    <DataWrapper>
      <TextBox>
        <TitleBox>
          <Box>
            <StyledLabelText>Telefon </StyledLabelText>
          </Box>
          <Box>
            <StyledLabelText>E-mail </StyledLabelText>
          </Box>
        </TitleBox>
        <DataBox>
          <Box>
            <StyledText>{phone}</StyledText>
          </Box>
          <Box>
            <StyledText>{email}</StyledText>
          </Box>
        </DataBox>
      </TextBox>
      <NotificationSection
        enabled={notificationsActive}
        toggleNotifications={toggleNotifications}
        error={notifiactionsError}
        notificationsSupported={notificationsSupported}
      />
    </DataWrapper>
    <ButtonWrapper>
      <DataError />
      <DeleteAccount />
    </ButtonWrapper>
  </Container>
);

UserProfileComponent.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  avatarPath: PropTypes.string,
  notificationsActive: PropTypes.bool.isRequired,
  toggleNotifications: PropTypes.func.isRequired,
  notifiactionsError: PropTypes.string,
  notificationsSupported: PropTypes.bool.isRequired
};

UserProfileComponent.defaultProps = {
  name: '',
  phone: '',
  email: '',
  avatarPath: '',
  notifiactionsError: null
};

export default UserProfileComponent;
