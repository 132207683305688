import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import AvatarImage from './components/AvatarImage';
import AvatarBadge from './components/AvatarBadge';

const AvatarWrapper = styled.div`
  position: relative;
  width: 3.5em;
`;

const AvatarIcon = ({
  userRole,
  path,
  width,
  height,
  borderWidth,
  hideBorder,
  showBadge
}) => {
  return (
    <AvatarWrapper>
      <AvatarImage
        path={path}
        userRole={userRole}
        width={width}
        height={height}
        borderWidth={borderWidth}
        hideBorder={hideBorder}
      />
      {showBadge && <AvatarBadge userRole={userRole} width={width} />}
    </AvatarWrapper>
  );
};

AvatarIcon.propTypes = {
  userRole: PropTypes.string,
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  borderWidth: PropTypes.string,
  hideBorder: PropTypes.bool,
  showBadge: PropTypes.bool
};

AvatarIcon.defaultProps = {
  userRole: null,
  path: '',
  width: null,
  height: null,
  hideBorder: false,
  borderWidth: null,
  showBadge: true
};

export default AvatarIcon;
