import { getRequestHeaders, handleResponse } from '../request';

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * It gets all notifications for current user
 * @param {number} page
 * @param {number} limit
 */
export const fetchNotifications = (page = 1, limit = 10) => {
  const requestOptions = {
    method: 'GET',
    headers: getRequestHeaders()
  };

  return fetch(
    `${apiUrl}/notifications?page=${page}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
};

export const changeNotificationStatus = notificationId => {
  const requestOptions = {
    method: 'PUT',
    headers: getRequestHeaders()
  };
  return fetch(
    `${apiUrl}/notification/${notificationId}/toggle`,
    requestOptions
  ).then(handleResponse);
};
