import React from 'react';
import styled, { css } from 'styled-components';

import Logo from '../../shared/components/Logo';
import WelcomePicture from '../../../assets/welcome';

const LoginWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: ${({ theme }) => theme.primary};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: row;
    height: 100%;
  }
`;
const sectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 8%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    padding: 1em 6em;
    margin-top: 20em;
    flex: 1;
    max-width: 400px;
    width: 50%;
    height: 100%;
  }
`;
const WelcomeSection = styled.div`
  ${sectionStyle};
  height: 35%;
  svg {
    width: 100%;
  }
`;
const FormSection = styled.div`
  ${sectionStyle};
  height: 65%;
  justify-content: space-between;
  text-align: center;
  border-left: 1px solid ${({ theme }) => theme.borderColor};
  svg {
    max-width: 400px;
  }
  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    margin-top: 10%;
    flex: 1;
  }
  button {
    margin: 2em auto 0;
  }
`;

const WelcomeContainer = props => (
  <LoginWrapper>
    <WelcomeSection>
      <WelcomePicture />
    </WelcomeSection>
    <FormSection>
      <Logo />
      {props.children}
    </FormSection>
  </LoginWrapper>
);

export default WelcomeContainer;
