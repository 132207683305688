import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ThumbNo from '../../../../assets/thumbNo';
import ThumbYes from '../../../../assets/thumbYes';
import { VOTES } from '../../../helpers/constants';
import { Caption, NavItem } from '../../../layout/theme/components';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import FileInput from '../../../shared/components/forms/FileInput';

const Wrapper = styled.div`
  transition: all 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  height: ${({ hide }) => (hide ? '6em' : 'auto')};
  overflow: hidden;
`;
const ButtonWrapper = styled.div`
  position: relative;
  margin-top: 1em;
`;
const StyledAlert = styled(Caption)`
  margin: 1em 0;
  display: block;
`;

const ResolutionThumbs = ({
  chooseThumb,
  isThumbChosen,
  allowCommunityVote,
  openSoundingPlug,
  isVotingClosed,
  voteHandler,
  attachment,
  handleFileChange,
  handleRemoveFile
}) => {
  return (
    <Wrapper hide={!isThumbChosen && !attachment}>
      <ThumbRadio changeHandler={c => chooseThumb(c)} />
      {attachment && (
        <>
          <StyledAlert>
            Oddając głos za mieszkańca, pamiętaj o konieczności udokumentowania
            tego oświadczenia. Możesz też załączyć plik z potwierdzeniem głosu
            poniżej.
          </StyledAlert>
          <FileInput
            title={'Dodaj załącznik'}
            changeHandler={value => handleFileChange(value)}
            files={attachment}
            removeFile={() => handleRemoveFile()}
            maxFilesQuantity={1}
            bottomMargin
          />
        </>
      )}
      <ButtonWrapper>
        <SecondaryButton
          onClick={allowCommunityVote ? voteHandler : openSoundingPlug}
          disabled={isVotingClosed || !isThumbChosen}
        >
          GŁOSUJ UCHWAŁĘ
        </SecondaryButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

ResolutionThumbs.propTypes = {
  chooseThumb: PropTypes.func.isRequired,
  isThumbChosen: PropTypes.bool,
  allowCommunityVote: PropTypes.bool,
  openSoundingPlug: PropTypes.func,
  isVotingClosed: PropTypes.bool,
  voteHandler: PropTypes.func,
  attachment: PropTypes.array,
  handleFileChange: PropTypes.func,
  handleRemoveFile: PropTypes.func
};
ResolutionThumbs.defaultProps = {
  isThumbChosen: false,
  allowCommunityVote: false,
  openSoundingPlug: false,
  isVotingClosed: false,
  voteHandler: null,
  attachment: null,
  handleFileChange: null,
  handleRemoveFile: null
};

export default ResolutionThumbs;

const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6em;
  margin: 0 auto;
`;
const Radio = styled.div`
  display: block;
  cursor: pointer;
  position: relative;
  width: 23px;
  height: 23px;
  margin: 0 2em 0.5em 0;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);

  svg {
    fill: #ffffff;
    height: 19px;
    position: absolute;
    right: -5px;
    bottom: -5px;
  }
`;
const RadioBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
  height: 100%;
  padding: 0 5%;
  background: ${({ color }) => color};
  color: #ffffff;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;
const StyledNavItem = styled(NavItem)`
  color: #ffffff;
`;

const ThumbRadio = ({ changeHandler }) => {
  const [vote, setVote] = useState(0);
  useEffect(() => changeHandler(vote), [vote]);

  return (
    <RadioWrapper>
      <RadioBox
        onClick={() => setVote(1)}
        isActive={vote === 1}
        color={VOTES['positive'].color}
      >
        <Radio radio="yes">
          <ThumbYes />
        </Radio>
        <StyledNavItem active={vote === 1}>Jestem za</StyledNavItem>
      </RadioBox>
      <RadioBox
        onClick={() => setVote(-1)}
        isActive={vote === -1}
        color={VOTES['negative'].color}
      >
        <Radio radio="no">
          <ThumbNo />
        </Radio>
        <StyledNavItem active={vote === -1}>Jestem przeciw</StyledNavItem>
      </RadioBox>
    </RadioWrapper>
  );
};

ThumbRadio.propTypes = {
  changeHandler: PropTypes.func.isRequired
};
