import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import Notification from '../community/NotificationComponent';
import CaseHeader from './components/CaseHeader';
import ProgressDots from './components/ProgressDots';

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    flex-direction: row;
  }
`;
const sectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    width: 70%;
    max-width: 900px;
  }
`;
const StepSection = styled.div`
  ${sectionStyle};
  justify-content: space-between;
  padding: 8% 10%;
  flex: 1;
`;

const StepComponent = props => {
  const {
    children,
    goBackHandler,
    categoryIcon,
    rangeIcon,
    picturePath,
    categoryColor,
    showCategoryPic,
    categoryName,
    allSteps,
    currentStep,
    prompt
  } = props;
  return (
    <StepWrapper>
      <CaseHeader
        goBackHandler={goBackHandler}
        categoryIcon={categoryIcon}
        rangeIcon={rangeIcon}
        picturePath={picturePath}
        categoryColor={categoryColor}
        showCategoryPic={showCategoryPic}
      >
        {categoryName}
      </CaseHeader>
      <StepSection>
        {children}
        {currentStep && (
          <ProgressDots allSteps={allSteps} currentStep={currentStep} />
        )}
      </StepSection>
      {prompt && <Notification>{ReactHtmlParser(prompt)}</Notification>}
    </StepWrapper>
  );
};

StepComponent.propTypes = {
  categoryName: PropTypes.string.isRequired,
  goBackHandler: PropTypes.func.isRequired,
  categoryIcon: PropTypes.string,
  rangeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  picturePath: PropTypes.string,
  prompt: PropTypes.string,
  categoryColor: PropTypes.string,
  allSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number,
  showCategoryPic: PropTypes.bool.isRequired
};

StepComponent.defaultProps = {
  categoryIcon: null,
  rangeIcon: null,
  picturePath: null,
  categoryColor: '#8e8e8e',
  prompt: null,
  currentStep: null
};

export default StepComponent;
