import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import CategoryPanel from '../components/CategoryPanel';
import PageWrapper from '../components/PageWrapper';
import TutorialHeader from '../components/TutorialHeader';

const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 90vh;
`;

const Step6 = ({ nextSlide, categories }) => (
  <PageWrapper onButtonClick={nextSlide}>
    <TutorialHeader>
      Każda sprawa jest przypisana do jednej z wielu <b>kategorii</b>.
    </TutorialHeader>
    <CategoriesWrapper>
      <CategoryPanel categories={categories} />
    </CategoriesWrapper>
  </PageWrapper>
);

Step6.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  nextSlide: PropTypes.func.isRequired
};

export default Step6;
