import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import PinchZoomPan from './PinchZoomPan';

import PdfFileDisplay from './PdfFileDisplay';

const TopBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 1% 0;
  opacity: 1;
  overflow: hidden;
`;
const StyledImage = styled.img`
  display: flex;
`;

const SliderTop = ({ path, isPDF }) => (
  <TopBox path={path}>
    {isPDF ? (
      <PdfFileDisplay path={path} />
    ) : (
      <PinchZoomPan
        path={path}
        zoomButtons={false}
        maxScale={10}
        position="center"
        doubleTapBehavior="reset"
      >
        <StyledImage alt="slide" src={path} />
      </PinchZoomPan>
    )}
  </TopBox>
);

SliderTop.propTypes = {
  path: PropTypes.string.isRequired,
  isPDF: PropTypes.bool
};

SliderTop.defaultProps = {
  isPDF: false
};

export default SliderTop;
