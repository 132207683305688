import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { fetchFile } from './api/media';

export const initializeReactGA = () => {
  const history = createBrowserHistory();
  ReactGA.initialize('UA-129702720-1');
  history.listen(location =>
    ReactGA.pageview(location.pathname + location.search)
  );
};

export const floatNumbers = (number, places) =>
  parseFloat((Math.round(number * 100) / 100).toFixed(places));

export const roundNumbers = (number, places) =>
  (Math.round(number * 100) / 100).toFixed(places);

export const formatVote = number =>
  (Math.round(number * 100) / 100).toFixed(2) + '%';

export const formatCurrency = number =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' zł';

export const sendFilesSize = file =>
  ReactGA.event({
    category: 'Files',
    action: 'zbyt duży rozmiar pliku',
    label: file.name,
    value: file.size
  });

export const clickedFreeTrial = () =>
  ReactGA.event({
    category: 'Clicked Actions',
    action: 'Wypróbuj bezpłatnie'
  });

export const clickedInviteNeighbours = menu =>
  ReactGA.event({
    category: 'Clicked Actions',
    action: 'Zaproś sąsiadów',
    label: menu && 'Kliknięte w menu'
  });

export const clickedAddCaseSearchBar = () =>
  ReactGA.event({
    category: 'Clicked Actions',
    action: 'Dodaj sprawę - wyszukiwarka'
  });

const createCanvas = (file, width = 640) => {
  const image = new Image();
  const canvas = document.createElement('canvas');
  canvas.width = width;
  image.src = URL.createObjectURL(file);

  return new Promise(resolve => {
    image.onload = () => {
      canvas.height = (image.height * width) / image.width;
      resolve({ canvas, image });
    };
  });
};

export const resizeFile = file => {
  const extension = file.name.split('.').pop();

  return createCanvas(file).then(({ canvas, image }) => {
    const pica = require('pica')();
    return pica
      .resize(image, canvas, { quality: 3 })
      .then(res => pica.toBlob(res, `image/${extension}`, 0.9))
      .then(blob => new File([blob], file.name));
  });
};

export const createTextLinks = text => {
  /*eslint-disable */
  return (text || '').replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    (match, space, url) => {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) hyperlink = 'https://' + hyperlink;
      /*eslint-enable */
      return (
        space +
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        '</a>'
      );
    }
  );
};

export const openPdfFile = caseId =>
  fetchFile(caseId).then(response => {
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
  });
