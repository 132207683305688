import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { H1, Text } from '../../theme/components';
import ChristinTutor from '../components/ChristinTutor';
import PageWrapper from '../components/PageWrapper';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin-bottom: 15%;
  }
`;
const ChristinTutorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  margin: 5% 0;
`;
const StyledText = styled(Text)`
  margin: 1em 0;
`;

const Step1 = ({ nextSlide, name, address, isFemale }) => (
  <PageWrapper onButtonClick={nextSlide}>
    <TextWrapper>
      <H1>Witaj!</H1>
      <StyledText defaultColor>
        {isFemale ? 'Zalogowałaś' : 'Zalogowałeś'} się jako <br />
        <strong>{name}</strong>
        <br />
        Twój adres mieszkania to: <br />
        <strong>{address}</strong>
      </StyledText>
      <Text>
        <strong>Mam na imię Krysia.</strong>
        <br />
        Wyjaśnię Ci najważniejsze funkcje, dzięki którym będziesz mógł wspierać
        i rozwijać swoją wspólnotę.
      </Text>
    </TextWrapper>
    <ChristinTutorBox>
      <ChristinTutor />
    </ChristinTutorBox>
  </PageWrapper>
);

Step1.propTypes = {
  nextSlide: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isFemale: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired
};

export default Step1;
