import React from 'react';
import styled from 'styled-components/macro';
import { Alert, H2, Text } from '../../../layout/theme/components';

const CaptionContainer = styled.div`
  margin: 2em 0;
  color: #585858;
`;

const ClosingModalContent = () => (
  <>
    <H2>Czy na pewno chcesz zamknąć głosowanie?</H2>
    <CaptionContainer>
      <Alert negative>
        Ważne! Po zamknięciu uchwały dodanie głosów offline za mieszkańców nie
        będzie możliwe.
      </Alert>
      <Text small>
        Po zamknięciu głosowania, wszyscy mieszkańcy otrzymają powiadomienie o
        podjęciu uchwały.
      </Text>
    </CaptionContainer>
  </>
);

export default ClosingModalContent;
