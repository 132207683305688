import PropTypes from 'prop-types';
import React, { Component, Fragment, memo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { fetchActivities, removeComment } from '../../helpers/api/activities';
import { fetchOpenCaseActivities } from '../../helpers/api/open';
import { getMonth, getYear } from '../../helpers/date';
import ActivityWrapper from './components/ActivityWrapper';
import AddComment from './components/AddComment';
import Comment from './components/Comment';
import CostEvent from './components/CostEvent';
import MonthTile from './components/MonthTile';
import ResolutionEvent from './components/ResolutionEvent';
import SoundingEvent from './components/SoundingEvent';
import TagEvent from './components/TagEvent';
import YearTile from './components/YearTile';

const MemoAddComment = memo(AddComment);
class ActivitiesContainer extends Component {
  constructor() {
    super();

    this.state = {
      activities: {},
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
  }

  componentDidMount() {
    this.getActivities(true);
  }

  getActivities(resetPage) {
    const { communityCase, open } = this.props;
    const page = resetPage ? 1 : this.state.currentPage + 1;
    const fetchData = open ? fetchOpenCaseActivities : fetchActivities;

    fetchData(communityCase, page, 10).then(({ data, paging }) => {
      if (data) {
        this.setState(state => ({
          activities: page === 1 ? data : [...state.activities, ...data],
          currentPage: paging.current_page,
          itemsPerPage: paging.items_per_page,
          totalItems: paging.total_items,
        }));
      }
    });
  }

  handleDeleteComment = (event, commentId) => {
    const { communityCase } = this.props;
    event.stopPropagation();
    removeComment(communityCase, commentId)
      .then(() => this.getActivities(true))
      .catch(error => this.setState({ error }));
  };

  handleAddComment(status) {
    if (status === 'success') {
      this.getActivities(true);
    }
  }

  renderActivity(data) {
    const { username } = this.props;
    switch (data.type) {
      case 'comment':
        return (
          <Comment
            key={uuid()}
            data={data.comment && data.comment}
            deleteComment={(e, commentId) => this.handleDeleteComment(e, commentId)}
            username={username}
          />
        );
      case 'sounding':
        return <SoundingEvent eventType={data.event_type} />;
      case 'resolution':
        return <ResolutionEvent eventType={data.event_type} triggeredBy={data.triggered_by} />;
      case 'community_cost':
        return <CostEvent communityCost={data.community_cost} userCost={data.user_cost} />;
      case 'company':
        return <TagEvent type={data.event_type} company={data.company ? data.company.display_name : ''} />;
      default:
        return null;
    }
  }

  renderActivities() {
    const { activities } = this.state;
    let currentMonth = getMonth(activities[0].activity_date, 'MM');
    let currentYear = getYear(activities[0].activity_date, 'YYYY');

    return activities.map((activity, index) => {
      let nextMonth = getMonth(activity.activity_date, 'MM');
      let nextYear = getYear(activity.activity_date, 'YYYY');
      const newMonth = currentMonth !== nextMonth;
      currentMonth = nextMonth;
      const newYear = currentYear !== nextYear;
      currentYear = nextYear;
      const displayYear = new Date().getFullYear() !== Number(currentYear);

      return (
        <Fragment key={index}>
          {newYear && <YearTile year={currentYear} />}
          {newMonth && <MonthTile month={currentMonth} year={displayYear ? currentYear : null} />}
          <ActivityWrapper newEvent={activity.new_activity} type={activity.type} date={activity.activity_date}>
            {this.renderActivity(activity)}
          </ActivityWrapper>
        </Fragment>
      );
    });
  }

  render() {
    const { communityCase, canAddComment } = this.props;
    const { activities, totalItems, itemsPerPage, currentPage } = this.state;
    return (
      <>
        {canAddComment && (
          <MemoAddComment
            addAsAnonymous={false}
            communityCase={communityCase}
            handleResponse={(status, resp) => this.handleAddComment(status, resp)}
          />
        )}
        {activities.length > 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.getActivities(false)}
            hasMore={totalItems / itemsPerPage > currentPage}
          >
            {this.renderActivities()}
          </InfiniteScroll>
        )}
      </>
    );
  }
}

ActivitiesContainer.propTypes = {
  communityCase: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  open: PropTypes.bool,
  canAddComment: PropTypes.bool.isRequired,
};

ActivitiesContainer.defaultProps = {
  open: false,
};

const mapStateToProps = state => ({
  username: state.user.profile.data ? state.user.profile.data.username : '',
});

export default connect(mapStateToProps)(ActivitiesContainer);
