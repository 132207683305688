import * as CaseActions from './actionTypes';
import { fetchUnvotedResolutions } from '../../helpers/api/case';

export const setActiveFilter = filter => ({
  type: CaseActions.SET_ACTIVE_FILTER,
  filter
});

export const queryCases = value => {
  return {
    type: CaseActions.SEARCHED_QUERY,
    value
  };
};

export const clearQuery = bool => {
  return {
    type: CaseActions.CLEAR_QUERY,
    bool
  };
};

export const setPageYOffset = value => {
  return {
    type: CaseActions.SET_OFFSET,
    value
  };
};

export const setUnvotedNumberStarted = value => {
  return {
    type: CaseActions.SET_UNVOTED_NUMBER_STARTED,
    value
  };
};
export const setUnvotedNumberSucceed = value => {
  return {
    type: CaseActions.SET_UNVOTED_NUMBER_SUCCEED,
    value
  };
};

export const setUnvotedResolutionsNumber = () => dispatch => {
  dispatch(setUnvotedNumberStarted());
  fetchUnvotedResolutions().then(data => {
    const waitingResolutions = data.data ? data.data.waiting : 0;
    dispatch(setUnvotedNumberSucceed(waitingResolutions));
  });
};
