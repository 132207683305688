import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { Text } from './../../../../../layout/theme/components';
import pdfIcon from '../../../../../../assets/pdfIcon.svg';

const StyledWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #454545;
  color: ${({ theme }) => theme.textColors.tertiary};
  margin: 1% 0;
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 3%;
  }
`;

const PdfFileDisplay = ({ path }) => (
  <StyledWrapper href={path} target="_blank" rel="noopener noreferrer">
    <img src={pdfIcon} alt="pdf" />
    <Text>Kliknij, aby otworzyć dokument</Text>
  </StyledWrapper>
);

PdfFileDisplay.propTypes = {
  path: PropTypes.string.isRequired
};

export default PdfFileDisplay;
