import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchCases } from '../../helpers/api/case';
import { LinkedItem } from '../../layout/theme/components';
import Loader from '../../shared/components/Loader';
import { ArchivesHeader } from './components/ArchivesHeader';
import { ArchivesWrapper } from './components/ArchivesWrapper';

class ListingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      isLoading: true,
      error: null,
      cases: [],
      currentPage: 1,
      itemsPerPage: 1,
      totalItems: 1
    };
  }

  activateArchives = () => {
    const { currentResource } = this.props;

    if (currentResource) {
      this.setState({ active: true });
      this.getCases(true);
    }
  };

  handleResponse(data, page) {
    return data.data
      ? this.setState(state => ({
          isLoading: false,
          error: null,
          cases: page === 1 ? data.data : [...state.cases, ...data.data],
          currentPage: data.paging.current_page,
          itemsPerPage: data.paging.items_per_page,
          totalItems: data.paging.total_items
        }))
      : this.setState({ isLoading: false, cases: [], currentPage: 1 });
  }

  getCases(resetPage) {
    const { activeFilter } = this.props;
    const page = resetPage ? 1 : this.state.currentPage + 1;
    if (resetPage) this.setState({ isLoading: true, error: null });

    fetchCases(page, activeFilter, 1)
      .then(data => this.handleResponse(data, page))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const {
      cases,
      currentPage,
      totalItems,
      itemsPerPage,
      active,
      isLoading,
      error
    } = this.state;
    const { renderCase, archivesCount } = this.props;
    const counterTextEnd =
      archivesCount === 1
        ? 'sprawa archiwalna'
        : archivesCount < 5
          ? 'sprawy archiwalne'
          : 'spraw archiwalnych';

    if (!active)
      return (
        <ArchivesWrapper>
          <LinkedItem onClick={this.activateArchives}>
            + {archivesCount} {counterTextEnd}
          </LinkedItem>
        </ArchivesWrapper>
      );
    if (isLoading)
      return (
        <ArchivesWrapper>
          <Loader />
        </ArchivesWrapper>
      );
    if (error)
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { error: true }
          }}
        />
      );
    return (
      <ArchivesWrapper>
        <ArchivesHeader />
        <InfiniteScroll
          pageStart={0}
          loadMore={() => this.getCases(false)}
          hasMore={totalItems / itemsPerPage > currentPage}
        >
          {cases.map((caseItem, index) => renderCase(caseItem, index, true))}
        </InfiniteScroll>
      </ArchivesWrapper>
    );
  }
}

ListingContainer.propTypes = {
  renderCase: PropTypes.func.isRequired,
  archivesCount: PropTypes.number,
  currentResource: PropTypes.number,
  activeFilter: PropTypes.string.isRequired
};

ListingContainer.defaultProps = {
  currentResource: null
};

const mapStateToProps = state => ({
  currentResource: state.user.currentResource,
  activeFilter: state.case.activeFilter
});

export default connect(mapStateToProps)(ListingContainer);
