import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import PageWrapper from '../components/PageWrapper';
import Arrow from '../components/Arrow';
import ChristinTutor from '../components/ChristinTutor';
import { H1, Text } from '../../theme/components';

import NotificationIcon from '../../../../assets/notification01';

const IconsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 9vh;
  padding-bottom: 1em;
  svg {
    width: 2.6em;
    height: 2.6em;
  }
`;
const ArrowsBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 1%;
`;
const HamburgerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.4em;
  height: 1.8em;
`;
const Line = styled.div`
  background: ${({ theme }) => theme.buttonColors.primary};
  display: block;
  width: 100%;
  height: 4px;
  border-radius: 15px;
`;
const TextBox = styled.div`
  width: 100%;
  text-align: center;
  margin: 1% 0 9%;

  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 14% 0;
  }
`;

const Step12 = ({ closeTutorial }) => (
  <PageWrapper closeTutorial={closeTutorial} hidden>
    <IconsBox>
      <HamburgerWrapper>
        <Line />
        <Line />
        <Line />
      </HamburgerWrapper>
      <NotificationIcon />
    </IconsBox>
    <ArrowsBox>
      <Arrow turn="upLeft" />
      <Arrow turn="upRight" />
    </ArrowsBox>
    <TextBox>
      <H1>To tyle!</H1>
      <Text>
        Jeśli będziesz mieć więcej pytań, <br />
        znajdziesz mnie tutaj!
      </Text>
    </TextBox>
    <ChristinTutor />
  </PageWrapper>
);

Step12.propTypes = {
  closeTutorial: PropTypes.func.isRequired
};

export default Step12;
