import { ellipsis } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '../../layout/theme/components';
import DropdownArrow from './DropdownArrow';

const Drop = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.backgroundColors.primary};
  margin: 10% 0 0;
  border: 1px solid ${({ theme }) => theme.buttonColors.inactive};
  width: 100%;
  padding: 0.5em 1em;
  font-weight: 600;
  cursor: pointer;
  height: 4em;
  z-index: 2;
`;
const StyledText = styled(Text)`
  ${ellipsis('100%')};
  color: ${({ theme }) => theme.textColors.secondary};
`;
const Down = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: ${({ theme }) => theme.backgroundColors.primary};
  width: 100%;
  padding: 0.5em;
  border-color: ${({ theme }) => theme.buttonColors.inactive};
  border-style: solid;
  border-width: 0 1px 1px 1px;
  cursor: pointer;
  z-index: 2;
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const DropdownComponent = ({
  toggleDropdown,
  showDropdown,
  header,
  children
}) => {
  const areChildren = children.length > 1;
  return (
    <>
      <Drop onClick={toggleDropdown}>
        <StyledText small bold>
          {header}
        </StyledText>
        {areChildren && <DropdownArrow rotate={showDropdown} />}
      </Drop>
      {showDropdown &&
        areChildren && (
          <>
            <Down>{children}</Down>
            <Backdrop onClick={toggleDropdown} />
          </>
        )}
    </>
  );
};

DropdownComponent.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default DropdownComponent;
