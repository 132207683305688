import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components/macro';

const InputWrapper = styled.div`
  margin: ${({ inline }) => (!inline ? '1em 0' : '0')};
  width: ${({ inline }) => (!inline ? '100%' : 'auto')};
`;
const Input = styled.input`
  display: block;
  width: 100%;
  border-width: 0 0 1px 0;
  padding: ${({ inline }) => (!inline ? `.4em;` : '.4em .1em')};
  text-align: left;
  font-size: 1.6em;
  border-color: ${({ valid, theme }) =>
    valid ? theme.borderColor : theme.labels.negative};
  outline: 0;
  ::placeholder {
    color: ${({ theme }) => theme.textColors.tertiary};
  }
  &&:focus {
    border-color: ${({ valid, theme }) =>
      valid ? theme.backgroundColors.tertiary : theme.labels.negative};
  }
  &&[type='number'] {
    -moz-appearance: textfield;
  }
  &:inner-spin-button,
  &:outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;
const InvalidText = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.labels.negative};
  font-size: 1.3em;
  margin: 3px 0 0;
  text-align: left;
`;
const Hint = styled.div`
  color: ${({ theme }) => theme.textColors.tertiary};
  font-size: 1.3em;
  margin: 3px 0 0;
  text-align: left;
`;

class TextInput extends Component {
  render() {
    const {
      isRequired,
      placeholder,
      value,
      onChange,
      name,
      type,
      valid,
      inputRef,
      invalidText,
      hint,
      autoFocus,
      inline
    } = this.props;
    return (
      <InputWrapper inline={inline}>
        <Input
          ref={inputRef}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          required={isRequired}
          valid={valid}
          onChange={onChange}
          autoFocus={autoFocus}
          inline={inline}
          keyboardtype={'email-address' || 'ascii-capable'}
        />
        {!valid && <InvalidText>{invalidText}</InvalidText>}
        {hint && <Hint>{hint}</Hint>}
      </InputWrapper>
    );
  }
}

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  valid: PropTypes.bool,
  inputRef: PropTypes.shape({ current: PropTypes.object }),
  invalidText: PropTypes.string,
  hint: PropTypes.string,
  inline: PropTypes.bool
};

TextInput.defaultProps = {
  type: 'text',
  onChange: null,
  placeholder: '',
  isRequired: false,
  autoFocus: false,
  valid: true,
  inputRef: null,
  invalidText: '',
  hint: null,
  inline: false
};

export default TextInput;
