import React from 'react';
import PropTypes from 'prop-types';

import FeatureComponent from './FeatureComponent';

import { featureContent } from './components/Content';

const FeatureContainer = ({
  match: {
    params: { feature }
  }
}) => {
  return (
    <FeatureComponent
      image={featureContent[feature].thumbnailImage}
      largeImage={featureContent[feature].largeImage}
      header={featureContent[feature].header}
      description={featureContent[feature].description}
      additionalDescription={featureContent[feature].additionalDescription}
      showBasicPremium={featureContent[feature].showBasicPremium}
    />
  );
};

FeatureContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      feature: PropTypes.string.isRequired
    })
  })
};

export default FeatureContainer;
