import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Container from '../shared/components/Container';
import { H0, Text } from '../layout/theme/components';
import AddResolutionIcon from '../../assets/resolution/addResolution';
import ArrowIcon from '../shared/components/ArrowIcon';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.primary};
  text-align: left;
`;
const IconBox = styled.div`
  margin-top: 2em;
  svg {
    width: 100%;
  }
`;
const TextBox = styled.div`
  text-align: left;
  margin-top: 3em;
  word-break: break-word;
`;

const ResolutionComponent = () => (
  <Container>
    <Link to="/">
      <ArrowIcon />
    </Link>
    <H0>Uchwała 48</H0>
    <IconBox>
      <AddResolutionIcon />
    </IconBox>
    <TextBox>
      <StyledText bold>Czym jest Uchwała48?</StyledText>
      <Text small>
        To najskuteczniejszy sposób podejmowania decyzji w Twojej wspólnocie.
        <br />
        <br />
        Stwórz uchwałę teraz: wybierz odpowiedni szablon. Możesz użyć czystej
        strony, wgrać swój własny plik lub wykorzystać przygotowany wzór. <br />
        <br />
        Następnie uzupełnij tytuł uchwały i wszystkie niezbędne dane. Dodaj
        załączniki, jeśli potrzebujesz. <br />
        <br />
        Głosowanie rozpoczyna się w momencie opublikowania uchwały.
      </Text>
    </TextBox>
    <TextBox>
      <StyledText bold>Głosuj używając Wspólnota.online!</StyledText>
      <Text small>
        Wszyscy mieszkańcy otrzymają mailową informację o rozpoczęciu głosowania
        oraz przypomnienie jeśli jeszcze nie zagłosowali. <br />
        <br />W każdym momencie mają dostęp do raportu oraz do pełnego protokołu
        głosowania po zakończeniu oddawania głosów.
      </Text>
    </TextBox>
  </Container>
);

export default ResolutionComponent;
