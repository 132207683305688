import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import NotificationIcon from '../../../assets/NotificationIcon';
import { H2 } from '../../layout/theme/components';
import ArrowIcon from './../../shared/components/ArrowIcon';
import Container from './../../shared/components/Container';

const StyledH2 = styled(H2)`
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    margin-right: 8px;
  }
`;
const ListingWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const NotificationsComponent = props => {
  return (
    <Container>
      <Link
        to={{
          pathname: '/',
          state: { updateUser: true }
        }}
      >
        <ArrowIcon />
      </Link>
      <StyledH2>
        <NotificationIcon /> Powiadomienia
      </StyledH2>
      <ListingWrapper>{props.children}</ListingWrapper>
    </Container>
  );
};

export default NotificationsComponent;
