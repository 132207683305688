import { isSameDay, isToday, parseISO } from 'date-fns';
import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { changeNotificationStatus, fetchNotifications } from '../../helpers/api/notifications';
import { formatFullDateMonthTranslated, formatTime } from '../../helpers/date';
import { Caption, Text } from '../../layout/theme/components';
import Loader from '../../shared/components/Loader';
import acceptResolution from './../../../assets/notifications/acceptResolution.svg';
import createCase from './../../../assets/notifications/createCase.svg';
import createResolution from './../../../assets/notifications/createResolution.svg';
import rejectResolution from './../../../assets/notifications/rejectResolution.svg';
import resolutionHalfVotes from './../../../assets/notifications/resolutionHalfVotes.svg';
import NotificationItem from './components/NotificationItem';
import NotificationsComponent from './NotificationsComponent';

export const NOTIFICATION_ICONS = {
  accept_resolution: acceptResolution,
  create_community_case: createCase,
  create_resolution: createResolution,
  new_comment: createCase,
  reject_resolution: rejectResolution,
  resolution_half_votes: resolutionHalfVotes,
};

class NotificationsContainer extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      notifications: {},
      currentPage: 1,
      itemsPerPage: 1,
      totalItems: 0,
    };
  }

  componentDidMount() {
    this.getNotifications(true);
  }

  getNotifications(resetPage) {
    const page = resetPage ? 1 : this.state.currentPage + 1;
    if (resetPage) this.setState({ isLoading: true });

    fetchNotifications(page, 10)
      .then(({ data, paging }) => {
        if (data) {
          this.setState(state => ({
            isLoading: false,
            notifications: page === 1 ? data : [...state.notifications, ...data],
            currentPage: paging.current_page,
            itemsPerPage: paging.items_per_page,
            totalItems: paging.total_items,
          }));
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleMark(e, notificationId) {
    e.stopPropagation();
    const { notifications } = this.state;
    const updatedNotifications = notifications.map(
      item => (item.id === notificationId ? { ...item, new: !item.new } : item)
    );

    changeNotificationStatus(notificationId).then(() => {
      this.setState({ notifications: updatedNotifications });
    });
  }

  handleNotificationClick(notificationId, isNew, caseId) {
    const location = {
      pathname: `/sprawa/${caseId}`,
      state: { goBack: true },
    };
    if (isNew) {
      changeNotificationStatus(notificationId).then(() => {
        this.props.history.push(location);
      });
    } else {
      this.props.history.push(location);
    }
  }

  renderNotifications() {
    const { notifications } = this.state;
    let currentDate;
    let dateChanged = true;

    return notifications.map((notification, index) => {
      let nextDate = notification.created_at;
      dateChanged = currentDate ? !isSameDay(parseISO(currentDate), parseISO(nextDate)) : true;
      currentDate = nextDate;

      return (
        <Fragment key={index}>
          {dateChanged && (
            <Caption>{isToday(parseISO(currentDate)) ? 'Dzisiaj' : formatFullDateMonthTranslated(currentDate)}</Caption>
          )}
          <NotificationItem
            community={notification.community_name}
            notificationTitle={notification.title}
            caseTitle={notification.body}
            isNew={notification.new}
            imageSrc={NOTIFICATION_ICONS[notification.type]}
            notificationTime={formatTime(notification.created_at)}
            clickHandler={() =>
              this.handleNotificationClick(notification.id, notification.new, notification.community_case_id)
            }
            checkHandler={e => this.toggleMark(e, notification.id, notification.new)}
          />
        </Fragment>
      );
    });
  }

  render() {
    const { isLoading, notifications, totalItems, itemsPerPage, currentPage } = this.state;

    if (isLoading) return <Loader />;

    return (
      <NotificationsComponent>
        {notifications.length > 0 ? (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.getNotifications(false)}
            hasMore={totalItems / itemsPerPage > currentPage}
          >
            {this.renderNotifications()}
          </InfiniteScroll>
        ) : (
          <Text>Brak powiadomień</Text>
        )}
      </NotificationsComponent>
    );
  }
}

export default NotificationsContainer;
