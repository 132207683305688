import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { RANGE_ICONS } from '../../helpers/constants';

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0.6em 0;
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6em 1em;
  background: ${({ highlighted }) => (highlighted ? '#30AB91' : '#C4C4C4')};
  color: #ffffff;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  white-space: pre-wrap;
  word-break: keep-all;
  max-width: 90%;

  svg {
    fill: #ffffff;
    margin-right: 0.5em;
  }
`;

const RangeLabel = props => {
  const { label, slug } = props;
  const isPublic = slug === 'public';

  return (
    <LabelWrapper>
      <Label highlighted={isPublic}>
        {RANGE_ICONS[slug]}
        Widoczność: <b>{label}</b>
      </Label>
    </LabelWrapper>
  );
};

RangeLabel.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
};

export default RangeLabel;
