import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Thumbnail from '../../../shared/components/Thumbnail';
import { Text } from '../../../layout/theme/components';
import { openPdfFile } from '../../../helpers/tools';

const ResolutionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`;
const StyledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.tertiary};
  margin-left: 0.5em;
`;

const PreviewResolutionFile = ({ resolution, caseId }) => (
  <ResolutionWrapper onClick={() => openPdfFile(caseId)}>
    <Thumbnail type="application/pdf" />
    <StyledText small>{resolution.name}</StyledText>
  </ResolutionWrapper>
);

PreviewResolutionFile.propTypes = {
  resolution: PropTypes.object.isRequired,
  caseId: PropTypes.string.isRequired
};

export default PreviewResolutionFile;
