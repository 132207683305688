import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CostMultipleIcon from '../../../assets/costMultipleIcon';
import { H2, InvalidText } from '../../layout/theme/components';
import { theme } from '../../layout/theme/globalStyle';
import SecondaryButton from '../../shared/components/buttons/SecondaryButton';
import TextButton from '../../shared/components/buttons/TextButton';
import TextInput from '../../shared/components/forms/TextInput';
import Modal from '../../shared/components/Modal';
import CostTile from '../components/CostTile';

const CostButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1em 0;
`;
const CostChangeWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
`;
const CostButton = styled(TextButton)`
  margin: 0;
  padding: 0.2em;
`;
const CostInvalidText = styled(InvalidText)`
  margin-top: 5px;
`;

const CostComponent = ({
  toggleModal,
  sendCost,
  handleCostChange,
  openModal,
  editable,
  cost,
  error
}) => {
  const renderModal = () =>
    openModal ? (
      <Modal closeModal={() => toggleModal(false)}>
        <H2 defaultColor>Wpisz koszt wspólnoty (zł)</H2>
        <TextInput
          name="cost"
          type="number"
          placeholder="0.00"
          onChange={e => handleCostChange(Number(e.target.value))}
          isRequired
        />
        {error && <CostInvalidText>{error}</CostInvalidText>}
        <SecondaryButton onClick={() => sendCost()}>WYŚLIJ</SecondaryButton>
      </Modal>
    ) : null;

  return (
    <>
      {editable &&
        !cost && (
          <CostButtonWrapper>
            <SecondaryButton onClick={() => toggleModal(true)}>
              DODAJ KOSZT WSPÓLNOTY
            </SecondaryButton>
          </CostButtonWrapper>
        )}
      <CostChangeWrapper>
        {editable &&
          cost && (
            <CostButton small onClick={() => toggleModal(true)}>
              Zmień
            </CostButton>
          )}
        {cost && (
          <CostTile
            cost={cost}
            costDescription="Koszt wspólnoty"
            backgroundColor={theme.backgroundColors.premium}
          >
            <CostMultipleIcon height="40px" width="100%" />
          </CostTile>
        )}
      </CostChangeWrapper>
      {renderModal()}
    </>
  );
};

CostComponent.propTypes = {
  toggleModal: PropTypes.func,
  sendCost: PropTypes.func,
  handleCostChange: PropTypes.func,
  openModal: PropTypes.bool,
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  error: PropTypes.string
};

CostComponent.defaultProps = {
  toggleModal: null,
  sendCost: null,
  handleCostChange: null,
  openModal: false,
  cost: null,
  editable: false,
  error: null
};

export default CostComponent;
