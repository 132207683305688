import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

import { LabelColored, Text } from '../../../layout/theme/components';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';
import TextButton from '../../../shared/components/buttons/TextButton';

import AttachmentIcon from '../../../../assets/attachment';
import CommentIcon from '../../../../assets/comment';
import draftIcon from '../../../../assets/draftIcon';
import pdfIcon from '../../../../assets/pdfIcon.svg';
import ThumbNo from '../../../../assets/thumbNo';
import ThumbYes from '../../../../assets/thumbYes';

import { formatDateTime } from '../../../helpers/date';
import { roundNumbers } from '../../../helpers/tools';
import ResolutionStatus from '../../preview/components/ResolutionStatus';

const ListItem = styled.div`
  cursor: pointer;
  min-height: 100px;
  padding: 1em 1.5em;
  border: 1px solid ${({ highlighted, theme }) => (highlighted ? theme.buttonColors.primary : theme.borderColor)};
  border-radius: 10px;
  margin: 0 1em 1em;
  max-width: 900px;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    margin: 0 auto 1em;
  }
`;
const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 13%;
  margin: 1%;
`;
const CaseDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  word-break: break-word;

  flex-direction: row;
  flex-wrap: wrap;
`;
const TopicText = styled(Text)`
  margin: 0.5em 0 1em;
  width: ${({ isDraft }) => (isDraft ? '85%' : '80%')};
  text-align: left;
  flex: 1;
`;
const SideData = styled.div`
  color: #8c8c8c;
  font-size: 1.2em;
  display: flex;
`;
const CaseActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;
const Counter = styled.span`
  display: flex;
  justify-self: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  font-size: 1.2em;
  margin: 0 0.5em;
  height: 2em;

  svg {
    height: 1em;
    margin-left: 0.3em;

    &:last-of-type {
      margin-left: 0.5em;
    }
  }
  + button {
    display: flex;
    align-self: flex-end;
  }
`;
const YourVote = styled.span`
  font-weight: 100;
`;

const Dot = styled.div`
  position: absolute;
  left: -1em;
  top: -0.5em;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${({ theme }) => theme.buttonColors.primary};
  @media (min-width: ${({ theme }) => theme.mobileBreakPoint}) {
    left: -1.5em;
    top: -1em;
  }
`;
const DraftInfo = styled.div`
  text-align: right;
  font-size: 1.2em;
  color: ${({ theme }) => theme.labels.negative};
`;

const DeleteDraft = styled(TextButton)`
  position: absolute;
  right: 0;
  top: -1em;
  font-size: 0.9em;
  padding-right: 0;
`;
const ResolutionIcon = styled.img`
  width: 26px;
  height: 25px;
`;
const VoteBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 7em;
`;
const ThumbBox = styled.div`
  width: 1em;
  height: 1em;
  svg {
    width: 1em;
    fill: ${({ color, theme }) => {
      switch (color) {
        case 'positive': {
          return theme.labels.positive;
        }
        default: {
          return theme.labels.negative;
        }
      }
    }};
  }
`;

const ResolutionItem = ({
  topic,
  newCase,
  newActivity,
  openSounding,
  userVote,
  userVoteLabel,
  clickHandler,
  isDraft,
  status,
  toggleModal,
  resolution,
  commentsCount,
  hasAttachment,
  canVote,
  createdAt,
}) => {
  const votesResults = resolution.results;
  const roundedPositiveVotes = roundNumbers(votesResults.positive_votes_percent, 0);
  const roundedNegativeVotes = roundNumbers(votesResults.negative_votes_percent, 0);
  return (
    <ListItem highlighted={newCase} onClick={clickHandler}>
      <IconWrapper>
        {newActivity && <Dot />}
        {isDraft ? (
          <ResolutionIcon as={draftIcon} />
        ) : (
          <>
            <ResolutionIcon src={pdfIcon} alt="pdf" />
            <VoteBox>
              <ThumbBox color="positive">
                <ThumbYes />
              </ThumbBox>
              <LabelColored>{roundedPositiveVotes}%</LabelColored>
              <ThumbBox color="negative">
                <ThumbNo />
              </ThumbBox>
              <LabelColored>{roundedNegativeVotes}%</LabelColored>
            </VoteBox>
          </>
        )}
      </IconWrapper>
      <CaseDetails>
        <TopicText isDraft={isDraft} bold>
          {topic}
        </TopicText>
        {isDraft ? (
          <>
            <DeleteDraft small onClick={toggleModal}>
              Usuń
            </DeleteDraft>
            <DraftInfo>Wersja robocza</DraftInfo>
          </>
        ) : (
          <>
            <SideData>{createdAt && formatDateTime(createdAt)}</SideData>
            <CaseActions>
              {
                <Counter>
                  {commentsCount}
                  {<CommentIcon />}
                  {hasAttachment && <AttachmentIcon />}
                </Counter>
              }
              {canVote ? (
                <SecondaryButton disabled={userVote !== null || status === 'closed'} onClick={openSounding}>
                  {userVote !== null ? (
                    <>
                      <YourVote>twój głos:</YourVote> {userVoteLabel}
                    </>
                  ) : (
                    'głosuj'
                  )}
                </SecondaryButton>
              ) : (
                <ResolutionStatus status={status} />
              )}
            </CaseActions>
          </>
        )}
      </CaseDetails>
    </ListItem>
  );
};

ResolutionItem.propTypes = {
  topic: PropTypes.string,
  hasAttachment: PropTypes.bool.isRequired,
  commentsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  clickHandler: PropTypes.func.isRequired,
  openSounding: PropTypes.func.isRequired,
  isDraft: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  userVote: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userVoteLabel: PropTypes.string,
  newCase: PropTypes.bool,
  newActivity: PropTypes.bool,
  canVote: PropTypes.bool,
  createdAt: PropTypes.string,
};

ResolutionItem.defaultProps = {
  topic: '',
  newCase: false,
  newActivity: false,
  userVote: null,
  userVoteLabel: '',
  canVote: false,
  createdAt: null,
};

export default ResolutionItem;
