import { combineReducers } from 'redux';
import { user as usersReducer } from './user/reducer';
import { cases as caseReducer } from './case/reducer';
import { LOGOUT } from './user/actionTypes';

const reducers = combineReducers({
  user: usersReducer,
  case: caseReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
