import React from 'react';
import PropTypes from 'prop-types';
import { VOTES } from '../../../helpers/constants';
import styled from 'styled-components';
import SecondaryButton from '../../../shared/components/buttons/SecondaryButton';

const Status = styled.div`
  color: ${({ color }) => color};
`;
const StatusLabel = styled.span`
  text-transform: uppercase;
`;

const VoteStatus = props => {
  const { vote, votedBy, canVoteByUser, onClick } = props;
  const voteProps = Object.values(VOTES).find(item => vote === item.value);

  return (
    <>
      {vote !== 0 && (
        <Status color={voteProps.color} small>
          <StatusLabel>{voteProps.label}</StatusLabel>
          {votedBy && ` (${votedBy})`}
        </Status>
      )}
      {canVoteByUser && (
        <SecondaryButton onClick={onClick} small>
          {vote === 0 ? 'Głosuj' : 'Zmień głos'}
        </SecondaryButton>
      )}
    </>
  );
};

VoteStatus.propTypes = {
  vote: PropTypes.number.isRequired,
  votedBy: PropTypes.string,
  canVoteByUser: PropTypes.bool,
  onClick: PropTypes.func
};

VoteStatus.defaultProps = {
  votedBy: null,
  canVoteByUser: false,
  onClick: null
};

export default VoteStatus;
