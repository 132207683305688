import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import CostSingleIcon from '../../../../assets/costSingleIcon';
import { Caption } from '../../../layout/theme/components';
import { theme } from '../../../layout/theme/globalStyle';
import CostTile from '../../components/CostTile';
import CostContainer from '../../cost/CostContainer';
import PreviewSection from './PreviewSection';

const CostsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  color: #ffffff;
  text-align: right;
  font-weight: 500;
  line-height: 1.5;
`;

const SectionWrapper = ({ title, children }) => {
  return title ? (
    <PreviewSection title={title}>{children}</PreviewSection>
  ) : (
    <>{children}</>
  );
};

const PreviewCosts = ({
  communityCost,
  userCost,
  costDescription,
  caseId,
  editable
}) => (
  <SectionWrapper title={(userCost || communityCost) && 'Koszt wspólnoty:'}>
    <CostsWrapper>
      <CostContainer caseId={caseId} cost={communityCost} editable={editable} />
      {userCost && (
        <CostTile
          cost={userCost}
          costDescription="Twój szacowany koszt"
          backgroundColor={theme.backgroundColors.quaternary}
        >
          <CostSingleIcon height="30px" width="100%" />
        </CostTile>
      )}
    </CostsWrapper>
    {costDescription && <Caption>{costDescription}</Caption>}
  </SectionWrapper>
);

PreviewCosts.propTypes = {
  communityCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  costDescription: PropTypes.string,
  caseId: PropTypes.number,
  editable: PropTypes.bool
};

PreviewCosts.defaultProps = {
  communityCost: null,
  userCost: null,
  costDescription: null,
  caseId: null,
  editable: false
};

export default PreviewCosts;
