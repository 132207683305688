import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import DefaulAvatarImage from '../../../../../assets/defaultAvatar';

import { userRoles } from '../../../../helpers/constants';

const Avatar = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : '30px')};
  height: ${({ height }) => (height ? height : '30px')};
  background: ${({ path, theme }) =>
    path ? `url(${path}) no-repeat center` : theme.textColors.tertiary};
  background-size: cover;
  margin: 0 0.5em 0 0;
  border: ${({ borderWidth }) => (borderWidth ? borderWidth : '1.5px')} solid
    ${({ userRole, theme, hideBorder }) => {
      if (hideBorder) {
        return 'none';
      }
      switch (userRole) {
        case userRoles.ROLE_WARDEN: {
          return theme.userColors.warden;
        }
        case userRoles.ROLE_KEEPER: {
          return theme.userColors.keeper;
        }
        case userRoles.ROLE_COMPANY: {
          return theme.userColors.company;
        }
        case userRoles.ROLE_USER: {
          return theme.userColors.user;
        }
        case userRoles.ROLE_ANONYMOUS: {
          return theme.backgroundColors.primary;
        }
        default: {
          return 'none';
        }
      }
    }};
  border-radius: 50%;
  svg {
    fill: ${({ theme }) => theme.backgroundColors.primary};
    width: 1em;
  }
`;

const AvatarImage = ({
  path,
  userRole,
  width,
  height,
  borderWidth,
  hideBorder
}) => (
  <Avatar
    path={path}
    userRole={userRole}
    width={width}
    height={height}
    borderWidth={borderWidth}
    hideBorder={hideBorder}
  >
    {!path && <DefaulAvatarImage />}
  </Avatar>
);

AvatarImage.propTypes = {
  userRole: PropTypes.string,
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  borderWidth: PropTypes.string,
  hideBorder: PropTypes.bool
};

AvatarImage.defaultProps = {
  userRole: null,
  path: '',
  width: null,
  height: null,
  borderWidth: null,
  hideBorder: false
};

export default AvatarImage;
