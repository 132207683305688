import { ellipsis } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import TooltipWrapper from '../../../shared/components/TooltipWrapper';

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin: 0.5em 0 1.5em;
  padding: 1em;
  border: 1px solid ${({ theme }) => theme.borderColor};
  opacity: ${({ highlighted }) => (highlighted ? '1' : '0.5')};
  text-align: left;
`;
const NotificationImage = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60px;

  img {
    height: 53px;
  }
`;
const NotificationSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
  margin: 0.5em 1em;
  color: ${({ theme }) => theme.textColors.primary};
`;
const CommunityLabel = styled.div`
  font-size: 1.1em;
  color: ${({ theme }) => theme.textColors.tertiary};
`;
const Title = styled.div`
  font-size: ${({ small }) => (small ? '1.3em' : '1.5em')};
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  margin-top: 2px;
  ${ellipsis('100%')};
`;
const NotificationActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 1.4em;
`;
const CheckmarkWrapper = styled.div`
  padding: 0.3em;
`;
const Checkmark = styled.div`
  border: 1px solid ${({ theme }) => theme.borderColor};
  width: 14px;
  height: 14px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    display: ${({ checked }) => (checked ? 'flex' : 'none')};
    width: 9px;
    height: 9px;
    border-radius: 50%/50%;
    background: ${({ theme, checked }) =>
      checked ? theme.buttonColors.secondary : '#ffffff'};
  }
`;

const NotificationItem = props => {
  const {
    community,
    notificationTitle,
    caseTitle,
    notificationTime,
    isNew,
    imageSrc,
    clickHandler,
    checkHandler
  } = props;
  const tooltipContent = isNew
    ? 'Oznacz jako przeczytane'
    : 'Oznacz jako nieprzeczytane';

  return (
    <NotificationWrapper highlighted={isNew} onClick={clickHandler}>
      <NotificationImage>
        <img alt="" src={imageSrc} />
      </NotificationImage>
      <NotificationSummary>
        <CommunityLabel>{community}</CommunityLabel>
        <Title bold>{notificationTitle}</Title>
        <Title small>{caseTitle}</Title>
      </NotificationSummary>
      <NotificationActions>
        <div>{notificationTime}</div>
        <CheckmarkWrapper onClick={checkHandler}>
          <TooltipWrapper position="left" tooltip={tooltipContent}>
            <Checkmark checked={isNew} />
          </TooltipWrapper>
        </CheckmarkWrapper>
      </NotificationActions>
    </NotificationWrapper>
  );
};

NotificationItem.propTypes = {
  community: PropTypes.string.isRequired,
  notificationTitle: PropTypes.string.isRequired,
  caseTitle: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
  notificationTime: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  checkHandler: PropTypes.func.isRequired
};

export default NotificationItem;
