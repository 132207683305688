import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';

const TooltipBox = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
`;
const Tooltip = styled.div`
  position: absolute;
  top: -3.5em;
  left: ${({ position }) => (position === 'right' ? '0' : 'auto')};
  right: ${({ position }) => (position === 'right' ? 'auto' : '0')};
  display: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  padding: 1em;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.boxShadow.button};
  white-space: nowrap;
  cursor: default;
  background: ${({ theme }) => theme.backgroundColors.primary};
  color: ${({ theme }) => theme.textColors.secondary};
  font-weight: 500;
  ${TooltipBox}:hover & {
    display: block;
  }
`;

const TooltipWrapper = ({ children, tooltip, position }) => (
  <TooltipBox>
    {children}
    {tooltip && <Tooltip position={position}>{tooltip}</Tooltip>}
  </TooltipBox>
);

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  position: PropTypes.string
};

TooltipWrapper.defaultProps = {
  tooltip: null,
  position: 'right'
};

export default TooltipWrapper;
