import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Modal from '../../../shared/components/Modal';
import CommentForm from '../CommentForm';
import { Text } from '../../../layout/theme/components';
import ActivityWrapper from './ActivityWrapper';

const AddCommentContainer = styled.div`
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 6px;
  background: ${({ theme }) => theme.backgroundColors.secondary};
  color: ${({ theme }) => theme.textColors.tertiary};
  text-align: left;
  cursor: pointer;
  margin-left: 3em;
`;

class AddComment extends Component {
  constructor() {
    super();

    this.state = {
      openModal: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(toggle, event, callback) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ openModal: toggle }, () => {
      if (callback) {
        callback();
      }
    });
  }

  addResponse(status, resp) {
    const { handleResponse } = this.props;
    this.toggleModal(false, null, () => handleResponse(status, resp));
  }

  renderModal() {
    const { communityCase, addAsAnonymous } = this.props;
    const { openModal } = this.state;

    return openModal ? (
      <Modal closeModal={e => this.toggleModal(false, e)} isWide>
        <CommentForm
          communityCase={communityCase}
          addAsAnonymous={addAsAnonymous}
          handleResponse={(status, resp) => this.addResponse(status, resp)}
        />
      </Modal>
    ) : null;
  }

  render() {
    return (
      <ActivityWrapper>
        <AddCommentContainer onClick={e => this.toggleModal(true, e)}>
          <Text small>Dodaj komentarz</Text>
        </AddCommentContainer>
        {this.renderModal()}
      </ActivityWrapper>
    );
  }
}

AddComment.propTypes = {
  communityCase: PropTypes.number.isRequired,
  handleResponse: PropTypes.func.isRequired,
  addAsAnonymous: PropTypes.bool.isRequired
};

export default AddComment;
